<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Resumen del Documento" :breadcrumbs="breadcrumbs" :breadcrumbsItemDinamic="{ loading: loadingDetail, text: (activeDocument || {}).name}" :dense="true" :hiddenTab="true" :addPadding="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="retrieve" outlined>
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-btn class="mr-2" v-if="['FAI', 'SNT'].includes(instance.status)" @click="dialogReprocessSummarie=true" outlined><v-icon left>mdi-file-refresh</v-icon>Re-procesar el resumen</v-btn>
        <v-menu v-if="$vuetify.breakpoint.width <= 1920" close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined v-bind="attrs" v-on="on">
              <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item class="px-3" @click="downloadFile2('summaries', nameXML)" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Descargar documento</v-list-item-title>
            </v-list-item>
            <v-list-item class="px-3" v-if="['APR', 'OBS'].includes(instance.status)" @click="downloadFile2('summaries', `${instance.type}_${instance.number}`, null, 'voucher')" :ripple="false">
              <v-list-item-title class="body-1 grey-500--text">Descargar CDR</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- <v-btn class="ml-2" @click="modalEmail=true" outlined><v-icon left>mdi-email</v-icon>Enviar por mail</v-btn> -->
      </template>
    </mini-header>
    <!-- end header -->

    <v-col class="px-0" style="z-index: 0;">
      <skeleton-detail v-if="loadingDetail" />
      <div class="d-flex" v-else>
        <!-- detail card -->
        <div class="mr-6" style="min-width: 400px;">
          <div style="position:fixed!important;">
            <v-img contain :width="400" :height="110" :src="require(`@/assets/backgrounds/background-document-detail.svg`)">
              <span class="px-4 pt-4 subtitle-2 white--text font-weight-bold d-inline-block text-truncate" style="max-width: 300px;">
                {{nameXML}}
              </span>
              <v-row class="ml-2 mt-3" align="end" no-gutters>
                <v-col cols="5" class="py-0 px-2">
                  <span class="d-block body-1 white--text">Fecha</span>
                  <span class="subtitle-2 white--text">
                    <span class="font-weight-bold">{{instance.date | date}}</span>
                  </span>
                </v-col>
              </v-row>
            </v-img>
            <v-card class="pa-0 mt-4" width="400" transition="slide-y-transition" min-height="calc(100% - 278px)" flat style="position:fixed!important;">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-bold">Resumen del documento</v-toolbar-title>
            </v-toolbar>
            <v-card-text class="overflow-y-auto pa-0" :style="$vuetify.breakpoint.height <= 700 ? 'max-height: calc(100vh - 353px)' : 'max-height: calc(100vh - 300px)'">
              <v-divider />
              <!-- detail -->
              <div class="py-4 px-5">
                <v-list class="v-list-form-main transparent pa-0">
                  <!-- <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Razón Social</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{(instance || {}).customer_name }}</v-list-item-subtitle>
                  </v-list-item> -->
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{ (instance || {}).tax_id }}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Tipo de documento</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{activeDocument?.name ?? ''}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="instance.status !== 'draft'">
                    <v-list-item-title class="grey-500--text subtitle-2">Resumen ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{nameXML}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip color="grey-background" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="grey-300--text" @click="toClipboard(instance.id)" text v-on="on" :ripple="false">{{instance.id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Fecha de ref.</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.reference_date | date}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2">Creado el</v-list-item-title>
                    <v-list-item-subtitle class="body-1">{{instance.created | dateTimeSecond}}</v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
              <!-- end detail -->
            </v-card-text>
          </v-card>
        </div>
        </div>
        <!-- detail card -->

        <!-- panels -->
        <div class="pb-5" style="flex:1; min-width: 180px;">

        <!-- SII / SUNAT -->
          <v-card class="mt-1" flat>
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">
                <v-row align="center" no-gutters>
                  <img class="ml-n1 mr-2" :src="require(`@/assets/ax/sunat--icon.svg`)" />
                  Sunat
                </v-row>
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <div class="pt-4 px-5 pb-3">
                <v-list class="v-list-form-status transparent pa-0">
                  <v-list-item class="pa-0">
                    <v-list-item-title class="grey-500--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="blue-500">mdi-clipboard</v-icon>Estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-btn v-if="!instance.status || instance?.status === 'IMP'" @click="taxAgencyService('documents', 'documents', 'irs_status')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Actualizar estado</v-btn>
                      <span v-else-if="instance?.status === 'PDR'" class="body-2 text--disabled d-block" >Sin estado</span>
                      <chip-status v-else :status="instance?.status" :detail="instance.agency_status || instance.agency_status_info ? `${instance.agency_status} <br> ${instance.agency_status_info}` : null"  :showDetail="true" :isTooltip="false" />
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="(instance || {}).agency_status">
                    <v-list-item-title class="grey-500--text subtitle-2 font-weight-bold"><v-icon class="mr-3" size="20" left color="blue-500">mdi-clipboard-search</v-icon>Detalle del estado</v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start mt-2" style="white-space: normal !important">{{instance.agency_status}}</v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="(instance || {}).agency_status_info">
                    <v-list-item-title class="grey-500--text subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start" style="white-space: normal !important">
                      <!-- <v-tooltip color="grey-background" right transition="scale-transition" max-width="500">
                        <template v-slot:activator="{on}"> -->
                          <v-sheet class="grey-300--text" v-on="instance.agency_status_info.length >= 256 ? on : false" style="justify-content: left;">
                            <span class="">{{instance.agency_status_info?.slice(0, 256)}} {{ instance.agency_status_info.length >= 256 ? '...' : ''}}</span>
                          </v-sheet>
                        <!-- </template>
                        <span class="d-block px-3 py-2">{{instance.agency_status_info}}</span>
                      </v-tooltip> -->
                    </v-list-item-subtitle>
                  </v-list-item>
                  <!-- agency_status_detail_list -->
                  <v-list-item class="pa-0" v-if="(instance || {})?.agency_status_detail_list">
                    <v-list-item-title class="grey-500--text subtitle-2"></v-list-item-title>
                    <v-list-item-subtitle class="body-1 align-self-start mt-2" v-if="(instance || {}).agency_status_detail_list" style="white-space: normal !important">
                      <span class="d-block mb-2" v-for="(detail, i) in instance?.agency_status_detail_list?.slice(0,2)" :key="i">{{ detail?.slice(0, 156) && i === instance.agency_status_detail_list?.slice(0,2).length - 1  && detail.length >= 156 ? `${detail}...` : detail}}</span>
                      <v-btn text color="blue-500" v-if="instance.agency_status_detail_list.length >=2" @click="dialogSunat=true">Ver más</v-btn>
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="pa-0" v-if="$store.state.auth.account.country !== 'PE'">
                    <v-list-item-title class="grey-500--text subtitle-2">Track ID</v-list-item-title>
                    <v-list-item-subtitle class="body-1">
                      <v-tooltip v-if="((instance || {}).status || {}).irs_id" color="grey-background" right transition="scale-transition" max-width="300">
                        <template v-slot:activator="{on}">
                          <v-btn class="grey-300--text" @click="toClipboard(((instance || {}).status || {}).irs_id)" text v-on="on" :ripple="false" style="justify-content: left;">{{((instance || {}).status || {}).irs_id}}</v-btn>
                        </template>
                        <span class="d-block px-3 py-2">Copiar</span>
                      </v-tooltip>
                      <span v-else-if="(((instance || {}).status || {}).id !== 'PDR')"><v-btn @click="taxAgencyService('documents', 'documents', 'irs_send')" :loading="taxAgencyLoader" text color="blue-500" :ripple="false">Enviar a la agencia fiscal</v-btn></span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </div>
            </v-card-text>
          </v-card>
          <!-- end SII / SUNAT -->

          <!-- events -->
          <v-card flat class="mt-5 d-none">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold"><v-icon size="18" left color="grey-700">mdi-timeline-clock</v-icon>Eventos</v-toolbar-title>
            </v-toolbar>
            <v-skeleton-loader v-if="eventLoading" class="mb-2 panel px-1 rounded-md" transition="fade-transition" type="list" />
            <template v-else-if="(eventsList || []).length ">
              <v-divider />
              <v-card-text class="pa-0">
                <v-list class="transparent px-0 py-2 dense">
                  <div v-for="(d, i) in events" :key="i">
                    <v-list-item class="px-5 py-0" :key="i">
                      <v-list-item-icon>
                        <v-icon color="blue-500">{{currentEvent(d.event_type).icon}}</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="ml-n5">
                        <span class="d-block grey-700--text body-1">
                          <span class="font-weight-bold">{{currentEvent(d.event_type).summary}}</span>
                        </span>
                        <span class="d-block body-2 grey-300--text">{{d.created | dateTimeSecond}}</span>
                       </v-list-item-title>
                    </v-list-item>
                    <v-sheet class="text-left ml-8 mt-n4 mb-n2" v-if="i !== eventsList.length - 1" height="18">
                      <v-icon size="3" color="placeholder" style="position: absolute; left: 30px">mdi-circle</v-icon>
                      <v-divider vertical class="my-0" />
                      <v-icon class="mb-n1" size="3" color="placeholder" style="left: -1px; top: 7px">mdi-circle</v-icon>
                    </v-sheet>
                    </div>
                  <template v-if="eventsList.length > 3">
                    <v-divider class="mt-4" />
                    <v-btn class="mt-1 mb-n1 mx-5" color="blue-500" @click="showMoreEvents=!showMoreEvents" text>
                      {{ !showMoreEvents ? 'Ver todos los eventos' : 'Ver menos eventos' }} </v-btn>
                  </template>
                </v-list>
              </v-card-text>
            </template>
          </v-card>
          <!-- end events -->

          <!--references -->
          <v-card class="mt-5" flat>
            <v-toolbar color="transparent" flat height="60">
              <v-toolbar-title class="d-flex align-center pl-5 subtitle-2 grey-700--text font-weight-semibold" style="min-width: 230px">
                <v-icon size="18" left color="grey-700">mdi-file-document-multiple</v-icon>
                Documentos referenciados
              </v-toolbar-title>
              <v-spacer />
            <v-toolbar-items>
              <v-row class="ma-0" align="center" justify="end" no-gutters>
                <v-col cols="4">
                  <v-text-field v-model="searchReferences.series" prefix="Serie" outlined required single-line dense maxlength="60" persistent-hint hide-details />
                </v-col>
                <v-col cols="4" class="mx-3">
                  <v-text-field v-model="searchReferences.serial" prefix="Correlativo" outlined required single-line dense maxlength="60" persistent-hint hide-details />
                </v-col>
                <v-col cols="1" class="pr-14">
                  <v-btn @click="filterByReferences" text color="blue-500">Filtrar</v-btn>
                </v-col>
              </v-row>
            </v-toolbar-items>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-data-table class="table-not-rounded" :headers="referencesHeaders" :items="currentReferencesFiltered" hide-default-header disable-sort hide-default-footer no-data-text="No se encontraron documentos" :items-per-page="10" @click:row="(currentReferencesFiltered) => { currentReferencesFiltered.document_id?.length > 0 ? goTo(currentReferencesFiltered.document_id) : '' }" style="cursor: pointer">
                <!-- header -->
                <template v-slot:header="{ props: { headers } }">
                  <VTableHeaders :headers="headers" @sort="sorting($event)"/>
                  <v-divider style="min-width: 100%; position: absolute;  margin-top: -6px; margin-left: -5px;" />
                </template>
                <!-- end header -->
                <template v-slot:[`item.type`]="{item}">
                  <span class="body-2">{{currentType(item.type)}}</span>
                </template>
                <template v-slot:[`item.number`]="{item}">
                  <span class="body-2">{{item.number}}</span>
                </template>
                <template v-slot:[`item.date`]="{item}">
                  <span class="body-2">{{item.date | date}}</span>
                </template>
                <template v-slot:[`item.document_id`]="{item}">
                  <v-tooltip v-if="item.document_id?.length > 0" color="grey-background" left transition="scale-transition" :max-width="280" open-on-hover>
                    <template v-slot:activator="{on}">
                      <v-chip class="px-2" v-on="on" color="grey-200" small label>
                        <v-icon color="grey-700">mdi-file-document-remove</v-icon>
                      </v-chip>
                    </template>
                    <div>
                      <span class="d-block px-3 py-2">Documento procesado</span>
                    </div>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <!-- end references -->
        </div>
        <!-- end panels -->
      </div>
    </v-col>

   <!-- dialog STATUS SUNAT -->
    <v-dialog v-model="dialogSunat" width="790" persistent scrollable no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Detalle del estado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSunat=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 pt-6 pb-0">
          <div v-for="(detail, i) in instance.agency_status_detail_list" :key="i">
            <span class="d-block mb-8">{{detail}}</span>
            <v-sheet class="text-left ml-1 mt-n8 mb-3" v-if="i !== instance.agency_status_detail_list.length - 1" height="10">
              <v-icon class="mt-1" size="3" color="placeholder" style="position: absolute; left: 22.5px;">mdi-circle</v-icon>
              <v-divider vertical class="my-0" />
              <v-icon class="mb-n1" size="3" color="placeholder" style="left: -0.4px; top: 7px">mdi-circle</v-icon>
            </v-sheet>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn color="blue-500" @click="dialogSunat=false" :ripple="false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog STATUS SUNAT -->

    <!-- dialog pdf -->
    <v-dialog v-model="modalPDF" width="700" persistent :scrollable="true" no-click-animation overlay-color="grey-500">
      <render-pdf :basicRender="true" :documentType="((instance || {}).type || '') | nameDocumentsType" :number="instance.number" @close="modalPDF=false" :loaderRender="loaderRender" :renderUrl="renderUrl" :flat="true" :formatPDF.sync="formatPDF" :showFormat="true" />
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- email -->
    <v-dialog v-model="modalEmail" width="700" persistent :scrollable="true" no-click-animation overlay-color="grey-500">
      <v-card min-height="300px">
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-500--text font-weight-semibold">Enviar documento por email</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalEmail=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pa-0" >
          <v-col class="pa-0 pb-8">
            <v-card class="transparent rounded-0 pa-0" flat>
              <v-card-subtitle class="background pt-5 pb-3 px-5">
                <div class="d-flex align-center">
                  <span class="grey-500--text body-1" style="min-width: 60px;">Para</span>
                  <v-combobox class="a-combobox" v-model="$v.messages.recipient.$model" :error="$v.messages.recipient.$error" hide-details :items="[]" outlined required hide-selected :placeholder="!messages.recipient ? 'Escribe el email del destinatario' : ''" @change="setEmails" multiple hide-no-data deletable-chips small-chips single-line dense autocomplete="off">
                    <template v-slot:selection="data">
                      <v-chip class="pr-2 ma-1" small v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)">{{ data.item }}</v-chip>
                    </template>
                  </v-combobox>
                </div>
                <div class="d-flex align-center mt-3">
                  <span class="grey-500--text body-1" style="min-width: 60px;">Asunto</span>
                  <v-text-field v-model="$v.messages.subject.$model" :error="$v.messages.subject.$error" outlined required single-line dense maxlength="60" hint="Escribe un asunto para el mensaje" persistent-hint :hide-details="!$v.messages.subject.$error" />
                </div>
                <v-col class="pa-0 mt-2">
                  <span class="grey-500--text body-1 mr-5">Archivos adjuntos</span>
                  <v-chip class="ma-1 pl-1 pr-2" color="grey-700" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="pdf" color="blue-500" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? instance.number?.replace(/[ ]/g, '') : instance.number}}.pdf
                  </v-chip>
                  <v-chip class="ma-1 pl-1 pr-2" color="grey-700" small outlined label>
                    <v-checkbox class="ma-0 pb-1" v-model="messages.type" value="xml" dense hide-details />
                    <v-icon left size="14">mdi-paperclip</v-icon>{{$store.state?.auth?.account?.country === 'PE' ? instance.number?.replace(/[ ]/g, '') : instance.number}}.xml
                  </v-chip>
                </v-col>
              </v-card-subtitle>
              <v-divider />
              <v-card-text class="px-0 mt-2 mx-auto bgPanel text-center" style="max-height: 450px;">
                <v-alert border="top" class="pa-0 mx-auto" color="var(--light-blue-500)" colored-border elevation="0" :style="`width: ${$vuetify.breakpoint.width < 1270 ? 465 : 564}px;`" />
                  <v-sheet class="mx-auto" :width="$vuetify.breakpoint.width < 1270 ? 470 : 570" style="border-radius: 10px; margin-top: -17px;" outlined>
                    <reminder-documents-email :instance="instance" :activeDocument="activeDocument" :color-background="account.color_background" :message.sync="messages.text" />
                  </v-sheet>
              </v-card-text>
            </v-card>
          </v-col>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-4 px-5">
          <v-spacer />
          <v-btn @click="dialogMessages=false" outlined>Cancelar</v-btn>
          <v-btn @click="createMessages" :loading="$store.state.messages.createLoader" color="blue-500">Enviar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end email -->

    <!-- dialog confirm reprocesar -->
    <v-dialog v-model="dialogReprocessSummarie" width="792" persistent no-click-animation overlay-color="grey-500">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Re-procesar resumen</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogReprocessSummarie=false" icon small retain-focus-on-click><v-icon color="grey-500" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 grey-500--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <v-icon class="mr-2" color="grey-700" size="14">mdi-file-document</v-icon><span class="body-2 font-weight-medium text-uppercase">{{activeDocument?.name ?? ''}} Nº {{instance.type?.slice(2,4) }}-{{instance.date | dateLigth}}-{{instance.serial}}</span>
              </div>
              <span class="d-block body-1 pt-2">Al confirmar, se enviará nuevamente este resumen a la SUNAT para su revisión y aceptación. Esta acción no se puede deshacer.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogReprocessSummarie=false" outlined>Cancelar</v-btn>
          <v-btn color="blue-500" @click="reprocessSummarie" :loading="loadingReprocessSummarie" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm reprocesar -->
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'
  import { orderBy } from 'lodash'
  import MiniHeader from '@/components/commons/MiniHeader'
  import VTableHeaders from '@/components/VTable/VTableHeaders'
  import { email, minLength, maxLength, required } from 'vuelidate/lib/validators'
  import { rutValidator } from 'vue-dni'
  import ChipStatus from '@/components/commons/ChipStatus'
  import VModal from '@/components/commons/VPanel'
  import SkeletonDetail from '@/components/commons/skeletonLoader/SkeletonDetail'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentsList from '@/modules/documents/components/DocumentsList'
  import DocumentEvents from '@/collections/documentEvents'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import TaxAgencyViewMixin from '@/mixins/TaxAgencyViewMixin'
  import summariesTypes from '@/collections/summariesTypes'
  import DocumentTypes from '@/collections/documentTypes'
  import numeral from 'numeral'
  import moment from 'moment'
  import VEmptyState from '@/components/commons/VEmptyState'

  export default {
    mixins: [
      GenericViewMixin,
      RenderViewMixin,
      TaxAgencyViewMixin
    ],
    components: {
      VTableHeaders,
      VEmptyState,
      ChipStatus,
      VModal,
      SkeletonDetail,
      RenderPdf,
      DocumentsList,
      MiniHeader
    },
    data: () => ({
      dialogReprocessSummarie: false,
      searchReferences: {
        serial: '',
        series: ''
      },
      currentReferences: [],
      currentReferencesFiltered: [],
      typeSort: 'asc',
      loadingReprocessSummarie: false,
      dialogSunat: false,
      link: 'https://docs.axteroid.com/',
      eventLoading: false,
      // events: [],
      showMoreEvents: false,
      confirm: '',
      dialogMarkAsAnulled: false,
      loadingAnulled: false,
      actionCreateDoc: '',
      cloneDoc: '',
      createDocumentDialog: false,
      formatPDF: 1,
      loadingDetail: false,
      loading: false,
      scroll: 0,
      activePanel: false,
      grant: {
        tax_id: '',
        name: '',
        address: '',
        email: '',
        assignor_email: ''
      },
      dialogGrant: false,
      dialogCreditNote: false,
      dialogMessages: false,
      loadingDocumentsDuplicate: false,
      panelIrsSii: [],
      panelRelatedDocuments: false,
      panelTotals: false,
      modalEmail: false,
      panelComments: false,
      modalPDF: false,
      comments: '',
      messages: {
        text: '',
        recipient: '',
        subject: '',
        type: ['pdf', 'xml']
      },
      DocumentTypesList: DocumentTypes,
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Resúmenes',
            route: 'SummariesList'
          }
        ]
      },
      referencesHeaders: [
        { text: 'Tipo de documento', value: 'type', class: 'font-weight-bold', sortable: true },
        { text: 'Motivo', value: 'description', sortable: false },
        { text: 'Serie-Correlativo', value: 'number', align: 'end', sortable: true },
        { text: 'Fecha', value: 'date', align: 'end', sortable: true },
        { text: 'Estado', value: 'document_id', align: 'end', sortable: true }
      ]
    }),
    computed: {
      ...mapState({
        instance: state => state.summaries.summariesDetail,
        supplierresponsesList: state => state.irs.supplierresponsesList,
        creditnotesList: state => state.summaries.creditnotesList,
        debitnotesList: state => state.summaries.debitnotesList,
        eventsList: state => state.summaries.eventsList,
        contactsList: state => state.customers.contactsList,
        count: state => state.summaries.summariesCount,
        summariesList: state => state.summaries.summariesList
      }),
      nameXML () {
        return `${this.instance?.type?.slice(2, 4)}-${moment(this.instance?.date).format('YYYYMMDD')}-${this.instance?.serial}`
      },
      totalCredits () {
        return this.sumProperty(this.creditnotesList, 'total')
      },
      totalDebits () {
        return this.sumProperty(this.debitnotesList, 'total')
      },
      totalAdjustedDocuments () {
        return this.instance.total - this.totalCredits + this.totalDebits
      },
      totalPaid () {
        return this.instance.payments.length ? this.instance.payments.map(c => c.amount_paid).reduce((a, b) => a + b) : 0
      },
      activeDocument () {
        return summariesTypes.find((d) => d.id === this.instance.type)
      },
      checkPermissionDTE () {
       return (dte) => {
          if (!this.$store.state.accounts.me?.is_owner && !this.$store.state.auth.userAccount.is_staff) {
            /* eslint-disable */
            const ownGroupPermissions = [...(this.$store.state.accounts.me?.document_type_permissions ?? []), ...(this.$store.state.accounts.me?.groups?.map(({document_type_permissions}) => document_type_permissions)[0] ?? [])]
            return ownGroupPermissions.filter((item) => item.document_type === dte.id)
          }
          return true
        }
      },
      events () {
        return !this.showMoreEvents ? this.eventsList.slice(0,3) : this.eventsList
      },
      currentEvent () {
        return (val) => {
          return DocumentEvents.find(({id}) => val === id) ?? {}
        }
      },
      currentType () {
        return (type = '') => {
          return DocumentTypes.find(({id}) => id === type)?.name ?? '-'
        }
      },
      currentParams () {
       let params = JSON.parse(JSON.stringify(this.$route.params))
       delete params.id
       return params
     },
    },
    watch: {
      modalPDF (val) {
        this.modalRender = val
        if (!this.renderUrl) this.renderDocument2('documents')
        this.formatPDF = 1
      },
      searchReferences:{
        handler (val) {
          if (!val.serial.length && !val.series.length) {
            this.currentReferencesFiltered = [...this.currentReferences]
          }
        },
        inmediate: true,
        deep: true
      }
    },
    created () {
      this.activePanel = true
      this.retrieve()
    },
    methods: {
      goTo (id = null) {
       this.$store.commit('base/SET_QUERY', this.$route.query)
       this.$router.push({ name: 'DocumentsRetrieve', params: { id: id, ...this.currentParams }})
     },
      filterByReferences () {
        this.currentReferencesFiltered = [...this.currentReferences]
        this.currentReferencesFiltered = [...this.currentReferencesFiltered.filter((item) => {
          if (this.searchReferences.serial.length > 0 && this.searchReferences.series.length > 0) {
            return item.number === `${this.searchReferences.series.toUpperCase()}-${this.searchReferences.serial}`
          } else if (this.searchReferences.serial.length > 0) {
            return item.serial.toString() === this.searchReferences.serial.toString()
          } else if (this.searchReferences.series.length > 0) {
            return item.series.toUpperCase() === this.searchReferences.series.toUpperCase()
          }
          return false
        })]
      },
      sorting (sort = '') {
        this.typeSort = this.typeSort === 'asc' ? 'desc' : 'asc'
        this.currentReferencesFiltered = orderBy(this.currentReferencesFiltered, [sort], [this.typeSort])
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      reprocessSummarie () {
        this.loadingReprocessSummarie = true
        this.$store.dispatch('summaries/GET', {
          resource: `summaries/${this.$route.params.id}/reprocess`
        })
        .then(() => {
          this.$dialog.message.info('El resumen fue reprocesado con éxito.')

          setTimeout(() => {
            this.retrieve()
          }, 600)
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.confirm = ''
          this.loadingReprocessSummarie = false
          this.dialogReprocessSummarie = false
        })
      },
      markAsAnulled () {
        this.loadingAnulled = true
        this.$store.dispatch('documents/GET', {
          resource: `documents/${this.$route.params.id}/void`
        })
        .then(() => {
          this.$dialog.message.info('El documento se marco como nulo con exito')

          setTimeout(() => {
            this.retrieve()
          }, 600)
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.confirm = ''
          this.loadingAnulled = false
          this.dialogMarkAsAnulled = false
        })
      },
      handlerClone (doc = '') {
        this.createDocumentDialog = true
        this.cloneDoc = doc
      },
      setEmails (e) {
        let array = []
        e.forEach(item => {
          let elm = item.replace(/ /g, ',')
          array = [...array, ...elm.split(',')]
        })
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
        this.messages.recipient = array.filter(item => regex.test(item))
      },
      retrieve () {
        this.currentReferencesFiltered = []
        this.currentReferences = []
        this.loadingDetail = true
        this.$store.dispatch('summaries/RETRIEVE', {
          resource: 'summaries',
          id: this.$route.params.id
        })
        .then(() => {
          if (this.instance.references?.length > 10) this.currentReferences = this.currentReferences?.slice(0,10) ?? this.currentReferences

          this.instance.references.forEach((item) => {
            this.currentReferences = [...this.currentReferences, {number: `${item.series}-${item.serial}`, ...item}]
          })
          this.currentReferencesFiltered = [...this.currentReferences]

          // this.eventLoading = true
          // this.$store.dispatch('summaries/LIST', {
          //   resource: `summaries/${this.instance.id}/events`
          // })
          this.messages.subject = `Envía ${this.activeDocument?.name ?? ''} N° ${this.instance.number}`
          this.messages.text = `Estimado cliente, ${this.instance?.customer_name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.instance.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.amount_total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
        })
        .finally(() => {
          // this.eventLoading = false
          this.loadingDetail = false
        })
      },
      createMessages () {
        this.messages.document = this.instance.id
        this.messages.type = this.messages.type.length === 2 ? 'all' : this.messages.type.toString()

        this.$v.messages.$touch()
        if (this.$v.messages.$invalid) {
          return false
        }
        this.$store.dispatch('messages/CREATE', {
          resource: 'messages',
          payload: this.messages
        })
        .then(() => {
          this.dialogMessages = false
          this.$dialog.message.info('Email enviado con éxito')
          this.messages.recipient = ''
          this.messages.type = []
          this.messages.text = `Estimado cliente, ${this.instance?.customer_name ?? ''},\n\n`
          this.messages.text += `Adjunto ${this.activeDocument?.name ?? ''} N° ${this.instance.number}, `
          this.messages.text += `correspondiente al ${moment(this.instance?.date).format('DD MMM YYYY')} `
          this.messages.text += `por un monto de ${numeral(this.instance?.amount_total).format('0,0')} ${this.instance?.exchange?.currency_from ?? this.instance?.exchange?.currency_to ?? this.activeDocument?.currencies[0] ?? ''} \n\nAtentamente ${this.account?.name}`
          this.$v.messages.$reset()
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
      },
      remove (item) {
        const index = this.messages.recipient.indexOf(item)
        if (index >= 0) {
          this.messages.recipient.splice(index, 1)
        }
      }
    },
    validations: {
      messages: {
        recipient: {
          required
        },
        subject: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(60)
        },
        text: {
          required
        }
      },
      grant: {
        tax_id: {
          required,
          maxLength: maxLength(32),
          rutValidator
        },
        name: {
          required,
          maxLength: maxLength(32)
        },
        address: {
          required,
          maxLength: maxLength(56)
        },
        email: {
          required,
          email,
          maxLength: maxLength(36)
        },
        assignor_email: {
          required,
          email,
          maxLength: maxLength(36)
        }
      }
    }
  }
</script>
<style>
.textarea-email.v-text-field--enclosed .v-input__append-inner {
  align-self: end;
  padding: 10px 0;
}
</style>